body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

h1 {
  outline-color: fuchsia;
  outline-style: ridge;
  color: white;
  justify-content: left;
  text-align: center;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
}

.NameLogo {
  font-family: 'Major Mono Display', monospace;
  text-align: center;
  font-size: 4em;
  padding-top: 50px;

}

body {
  background: url("/public/images/binding_dark.png");
  background-size: auto;
}

p {
  background-color: rgba(255, 200, 0, 1)
}





footer {
  text-align: center;
  color: #eeeeee;
  position: fixed;
  bottom: 0;
  font-size: small;
  width: 100%;
  padding-bottom: 5px;
}

.Socials {
  display: flex;
  width: 100%;
  height: auto;
  text-align: center;
  justify-content: center;
}

.Social {
  text-align: center;
  src: "./components/images/Octicons-mark-github.svg"
}

.LogoSocial {
  height: 30vmin;
  padding: 50px;
  z-index: 1;
  display: block;

  pointer-events: none;
}

.NameSocial {
  text-align: center;
}



#content {
  text-align: center;
  width: 800px;
  color: #eeeeee;
  margin: 0 auto;
}

