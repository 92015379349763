.splash-container {
    width: 100%;
  height: 100%;
  background: linear-gradient(-10deg, #3b1b55, #7817a8, #061c24, #5c6bd9);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;

}
.splash-wrap {
    justify-content: space-between;
    height: auto;
    width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  color: #ffffff;
}
.splash-welcome {
    font-size: 3em;
    text-align: left;
    padding-left: 5%;
    padding-top: 10%;
    font-weight: 700;
    color: #ffffff;
  }

.splash-welcome:hover {
  font-size: 3em;
  text-align: left;
  padding-left: 5%;
  padding-top: 10%;
  font-weight: 700;
  color: #ffffff;
  animation: colorRotate 6s linear 0s infinite;
}
.splash-left {
  width: 50%;
}
.splash-right {
  width: 50%;

}

.project-squares{
    display: flex;
    flex-wrap: wrap;

}
#project1{
    width: 49%;
    height: 300px;
    /* border: green 1px solid; */
    box-shadow: rgba(255, 255, 255, 0.1) 0px 20px 25px -5px, rgba(255, 255, 255, 0.04) 0px 10px 10px -5px;
    margin-top: 20px;
    position: relative;
    border-radius: 2em;

}
#project1:hover{
  width: 49%;
  height: 296px;
  border: white 2px solid;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 20px 25px -5px, rgba(255, 255, 255, 0.04) 0px 10px 10px -5px;
  margin-top: 20px;
  position: relative;
  border-radius: 2em;
}
#project2{
    height: 300px;
    width: 49%;
    /* border: red 1px solid; */
    box-shadow: rgba(255, 255, 255, 0.1) 0px 20px 25px -5px, rgba(255, 255, 255, 0.04) 0px 10px 10px -5px;
    margin-top: 20px;
    position: relative;
    border-radius: 2em;

}
#project2:hover{
  width: 49%;
  height: 296px;
  border: white 2px solid;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 20px 25px -5px, rgba(255, 255, 255, 0.04) 0px 10px 10px -5px;
  margin-top: 20px;
  position: relative;
  border-radius: 2em;
}
#project3{
    height: 300px;
    width: 49%;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 20px 25px -5px, rgba(255, 255, 255, 0.04) 0px 10px 10px -5px;
    position: relative;
    margin-top: 20px;
    border-radius: 2em;
}
#project3:hover{
  width: 49%;
  height: 296px;
  border: white 2px solid;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 20px 25px -5px, rgba(255, 255, 255, 0.04) 0px 10px 10px -5px;
  margin-top: 20px;
  position: relative;
  border-radius: 2em;
}
#project4{
    height: 300px;
    width: 49%;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 20px 25px -5px, rgba(255, 255, 255, 0.04) 0px 10px 10px -5px;
    margin-top: 20px;
    position: relative;
    border-radius: 2em;
}
#project4:hover{
  width: 49%;
  height: 296px;
  border: white 2px solid;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 20px 25px -5px, rgba(255, 255, 255, 0.04) 0px 10px 10px -5px;
  margin-top: 20px;
  position: relative;
  border-radius: 2em;
}
#proj1Image{
  background-image: url('../assets/projects/polydestroy.png');
  background-size: 100% 300px;
  /* width: 100%; */
  height: 300px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 2em;
}
#proj1Image:hover{
  background-image: url('../assets/projects/polydestroy.png');
  background-size: 100% 296px;
  /* width: 100%; */
  height: 296px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 2em;
}
#proj2Image{
  background-image: url('../assets/projects/archimagirus.png');
  background-size: 100% 300px;
  /* width: 100%; */
  height: 300px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 2em;
}
#proj2Image:hover{
  background-image: url('../assets/projects/archimagirus.png');
  background-size: 100% 296px;
  /* width: 100%; */
  height: 296px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 2em;
}
#proj3Image{
  background-image: url('../assets/projects/travelbag.png');
  background-size: 100% 300px;
  /* width: 100%; */
  height: 300px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 2em;
}
#proj3Image:hover{
  background-image: url('../assets/projects/travelbag.png');
  background-size: 100% 296px;
  /* width: 100%; */
  height: 296px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 2em;
}
#proj4Image{
  background-image: url('../assets/projects/draw.png');
  background-size: 100% 300px;
  /* width: 100%; */
  height: 300px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 2em;
}
#proj4Image:hover{
  background-image: url('../assets/projects/draw.png');
  background-size: 100% 296px;
  /* width: 100%; */
  height: 296px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 2em;
}
#projDesc{
  background-size: 100%;
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 2em;
  padding-right: 1em;
  padding-top: 2em;

}
.technologies{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
}
.tech{ 
  background-color: white;
  margin-top: 20px;
  margin-left: 5px;
  margin-right: 5px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: black;
  padding: 5px;
  
}
.projBottom{
  align-items: flex-end;
  bottom:0px;
  margin-top: auto;
  margin-bottom: 20px;

}
.projLinks{
  bottom: 10px;
  margin-top: auto;
}
.aboutLink{
  padding: 5px;
  color:white;
  text-decoration: none;
  background-color: #3b1b55;
}
.aboutLink:hover{
  padding: 5px;
  color:black;
  text-decoration: none;
  background-color: white;

}
.liveLink{
  padding: 5px;
  color:white;
  margin-right: 0px;
  border-top-left-radius: 10px;
  /* border-top-right-radius: 10px; */
  border-bottom-left-radius: 10px;
  /* border-bottom-right-radius: 10px; */
  text-decoration: none;
  background-color: #3b1b55;
}
.liveLink:hover{
  padding: 5px;
  color:black;
  background-color: white;
  margin-right: 0px;
  border-top-left-radius: 10px;
  /* border-top-right-radius: 10px; */
  border-bottom-left-radius: 10px;
  /* border-bottom-right-radius: 10px; */
  text-decoration: none;
}
.gitLink{
  padding: 5px;
  color:white;
  margin-left: 0px;
  /* border-top-left-radius: 10px; */
  border-top-right-radius: 10px;
  /* border-bottom-left-radius: 10px; */
  border-bottom-right-radius: 10px;
  background-color: #3b1b55;
  text-decoration: none;
}
.gitLink:hover{
  background-color: white;
  padding: 5px;
  margin-left: 0px;
  color:black;  
  /* border-top-left-radius: 10px; */
  border-top-right-radius: 10px;
  /* border-bottom-left-radius: 10px; */
  border-bottom-right-radius: 10px;
  text-decoration: none;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@keyframes colorRotate {
    from {
      color: #afaff3;
    }
    10% {
      color: #88b9da;
    }
    50% {
      color: #1dc21d;
    }
    75% {
      color: #634b57;
    }
    100% {
      color: #6c6cc9;
    }
  }

@media screen and (max-width:480px) {

  .splash-wrap {
    justify-content: space-between;
    height: auto;
    width: 100%;
    margin: 0;
    display: block;
    color: #ffffff;
  }

  .splash-container{
    background-color: #1dc21d;
  }

  .splash-welcome{
    height: 100%;
  }
  .splash-left {
    width: 100%;
    height: 100%;
  }
  .splash-right {
    width: 100%;
  }
  #project1{
    width: 100%;
  }
  #project2{
    width: 100%;
  }
  #project3{
    width: 100%;
  }
  #project4{
    width: 100%;
  }
  #project1:hover{
    width: 99%;
  }
  #project2:hover{
    width: 99%;
  }
  #project3:hover{
    width: 99%;
  }
  #project4:hover{
    width: 99%;
  }
  #proj1Image{
    width: 100%;
  }
  #proj2Image{
    width: 100%;
  }
  #proj3Image{
    width: 100%;
  }
  #proj4Image{
    width: 100%;
  }
  .project-squares{
    display: block;
    flex-wrap: wrap;

}
  
}