.nav-links {
    text-align: right;
    margin: 0 auto;
    padding-top: 30px;
    padding-right: 100px;
    display: flex;
    justify-content: flex-end;
    gap:5%;
    /* border: 2px solid red; */
  }

.nav-item {
    display: inline;
    /* border: 2px solid red; */
  }
  a {
      color: aliceblue;
  }
  #linkedin{
    background-image: url('../assets/logos/linkedin-icon.svg');
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 25px;
    border: 2px solid white;
    background-position: center;
  }
  #linkedin:hover{
    background-image: url('../assets/logos/linkedin-icon.svg');
    background-color: white;
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 25px;
    border: 2px solid white;
    background-position: center;
  }
  #github{
    background-image: url('../assets/logos/Octicons-mark-github.svg');
    background-color: white;
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 25px;
    border: 1px solid white;
  }
  #github:hover{
    background-image: url('../assets/logos/Octicons-mark-github.svg');
    background-color: grey;
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 25px;
    border: 1px solid white;
  }
  #email{
    background-image: url('../assets/logos/gmail-icon.svg');
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  #email:hover{
    background-image: url('../assets/logos/gmail-icon.svg');
    width: 46px;
    height: 46px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border:2px solid white;
  }